import React,{useState,useEffect} from 'react';
import Chart from '../../../components/Charts/Chart';
// import API from '../../../../api';

import _get from 'lodash/get';
const StackedChart=(props)=>{
  const [data,setData]=useState([])
  // const {assetExpenseGraph}=API;
        useEffect(()=>{
            // assetExpenseGraph().then((res)=>setData(res.data.data))
            return () => {
              setData([])
          };
        },[])
       const arr =['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
       const stack = [];
        const columns = [];
      const  label=[]
      const fuel=[];
      const Others=[];
      	const excelDataOperator = [];
		data.data && data.data.map(expense => {
            label.push(expense.month)
            fuel.push(parseInt(expense.fuel_expense))
            Others.push(parseInt(expense.other_expense))
			stack.push({Fuel: parseInt(expense.fuel_expense), Others: parseInt(expense.other_expense) })
		})
		const sum=[]
		sum.push({term:`$ ${(_get(data, 'sums.fuel_ytd', 0), 0)}`, span:'Fuel YTD'}, {term:`$ ${(_get(data, 'sums.others_ytd', 0), 0)}`, span:'Others YTD'})
          let  chartData={
            labels: label,
            datasets:
            [  
              {
                label:'Fuel',
                data:fuel,
                backgroundColor:'transparent',
                borderColor: 'rgb(0, 208, 189)',
                borderWidth: 2
              },
              {
                label:'Others',
                data:Others,
                backgroundColor:'transparent',
                borderColor: 'rgb(38, 88, 240)',
                borderWidth: 2
              },
            ]
          }

    return(
        fuel.length!==0&&  <Chart type='bar' chartData={chartData} location="Expenses" legendPosition="bottom"/>
    )
}
export default StackedChart;
